export default {
    name: '',
    components: {
      
    },
    data() {
      return {
        
      }
    },
    methods: {
      navigateTo(url) {
        this.$router.push({
          path: url
        });
      }
    },
    computed: {
      
    },
    watch: {
      
    }
  }